export default [
  {
    url: require('@/assets/img/auth/login/loginQuoteImage01.png')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage02.png')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage03.png')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage04.png')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage05.png')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage06.png')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage07.png')
  }
]
