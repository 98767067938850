<template>
  <v-app>
    <v-row no-gutters>
      <v-col cols="12" lg="7" class="d-none d-lg-block">
        <auth-login-image :image="require('@/assets/img/auth/login/loginQuoteImage01.png')" />
      </v-col>
      <v-col cols="12" lg="5">
        <authentication-service-provider
          ref="authenticationServiceProvider"
          v-slot="{ login, loginSocial, forgetPassword, loaders, forms, errorMessage, showSuccessAlertMailSent, passwordResetRequested }"
          @login-success="onLogin"
          @login-social-success="onLogin"
        >
          <div class="wrapper">
            <locale-changer class="pb-3" />

            <div v-if="showLoginForm" class="form-wrapper">
              <div class="title-wrapper"><!-- Title -->
                <h1>
                  {{ $t('auth.logIn.welcome') }}
                </h1>
                <!-- /Title -->
                <p>{{ $t('auth.logIn.yourIdeas') }}</p>
              </div>
              <!-- Alert -->
              <v-alert
                v-show="errorMessage"
                border="left"
                color="red"
                type="error"
              >
                {{ errorMessage }}
              </v-alert>
              <!-- /Alert -->

              <!-- Login with Google -->
              <v-btn
                class="button-google"
                block
                x-large
                elevation="0"
                outlined
                :disabled="loaders.socialLogin || loaders.login"
                :loading="loaders.socialLogin"
                color="secondary"
                @click="loginSocial('google')"
              >
                <img src="@/assets/img/logos/google.png" alt="google" class="mr-2">
                <span>{{ $t('auth.logInPayment.logInWithGoogle') }}</span>
              </v-btn>
              <!-- Login with Google -->

              <!-- Or -->
              <p class="line-through">
                {{ $t('auth.global.or') }}
              </p>
              <!-- /Or -->

              <validation-observer v-slot="{ invalid }">
                <v-form @submit.native.prevent="login()">
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="forms.login.email"
                      :label="$t('auth.global.email')"
                      type="email"
                      outlined
                      required
                      :disabled="loaders.login || loaders.socialLogin"
                      background-color="white"
                      :error-messages="errors"
                      :placeholder="$t('auth.global.enterEmail')"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    rules="required"
                  >
                    <v-text-field
                      v-model="forms.login.password"
                      :label="$t('auth.global.password')"
                      outlined
                      required
                      :type="showPassword ? 'text' : 'password'"
                      :disabled="loaders.login || loaders.socialLogin"
                      background-color="white"
                      :placeholder="$t('auth.global.enterPassword')"
                      :error-messages="errors"
                    >
                      <template #append>
                        <span @click="showPassword = !showPassword">
                          <v-icon>
                            {{ showPassword ? icons.mdiEye : icons.mdiEyeOff }}
                          </v-icon>
                        </span>
                      </template>
                    </v-text-field>
                  </validation-provider>

                  <v-btn
                    block
                    x-large
                    type="submit"
                    color="primary"
                    elevation="0"
                    :disabled="loaders.login || loaders.socialLogin || invalid"
                    :loading="loaders.login"
                    @click="login()"
                  >
                    <span class="font-outfit-semi-bold button-continue">{{ $t('continue') }}</span>
                  </v-btn>
                </v-form>
              </validation-observer>

              <!-- Terms & Conditions -->
              <p>{{ $t('auth.global.byContinuing') }}
                <a href="https://ideabuddy.com/terms-of-service/" target="_blank">{{ $t('auth.global.termsAndConditions') }}</a> {{ $t('auth.global.and') }}
                <a href="https://ideabuddy.com/privacy-policy/" target="_blank">{{ $t('auth.global.privacyPolicy') }}</a>.
              </p>
              <!-- /Terms & Conditions -->

              <p class="forgot-password">
                <router-link :to="{name: 'login', query: {forgetPassword: true}}">
                  {{ $t('auth.logIn.forgotPassword') }}
                </router-link>
              </p>

              <!-- Go To SignUp -->
              <p class="already-have">
                <span>{{ $t('auth.logIn.dontHave') }}</span>
                &nbsp;
                <router-link :to="{name: 'signup'}">
                  {{ $t('auth.logIn.signUp') }}
                </router-link>
              </p>
              <!-- /Go To SignUp -->

            </div>

            <div v-if="!showLoginForm" class="form-wrapper">
              <div class="title-wrapper"><!-- Title -->
                <h1>
                  {{ $t('auth.forgotPassword.title') }}
                </h1>
                <!-- /Title -->
                <p>{{ $t('auth.forgotPassword.enterEmail') }}</p>
              </div>

              <validation-observer v-slot="{ invalid }">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="forms.forgetPassword.email"
                    :label="$t('auth.global.email')"
                    type="email"
                    outlined
                    required
                    :disabled="loaders.forgetPassword || passwordResetRequested"
                    :error-messages="errors"
                    background-color="white"
                    :placeholder="$t('auth.global.enterEmail')"
                  />
                </validation-provider>

                <v-btn
                  block
                  x-large
                  color="primary"
                  elevation="0"
                  :disabled="invalid || loaders.forgetPassword"
                  :loading="loaders.forgetPassword"
                  @click="forgetPassword()"
                >
                  <span class="font-outfit-semi-bold button-continue">{{ $t('auth.forgotPassword.buttonGetNewPassword') }}</span>
                </v-btn>

              </validation-observer>

              <v-alert
                v-show="errorMessage"
                class="my-9"
                border="left"
                color="red"
                type="error"
              >
                {{ errorMessage }}
              </v-alert>

              <v-alert
                v-if="showSuccessAlertMailSent"
                class="my-9 text-start"
                text
                prominent
                type="success"
              >
                <strong>{{ $t('auth.forgotPassword.alert') }}</strong>
              </v-alert>

              <div>
                <p>{{ $t('auth.forgotPassword.dontHave') }}
                  <router-link :to="{name: 'signup'}">
                    {{ $t('auth.forgotPassword.signUp') }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </authentication-service-provider>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import { mdiEye, mdiEyeOff } from '@mdi/js'
import AuthLoginImage from '@/components/_v2/Common/AuthLoginImage.vue'
import AuthenticationServiceProvider from '@/components/ServiceProviders/AuthenticationServiceProvider.vue'
import LocaleChanger from '@/components/LocaleChanger.vue'
import MixinUser from '@/mixins/user'

export default {
  name: 'Login',

  components: { AuthenticationServiceProvider, LocaleChanger, AuthLoginImage },

  mixins: [MixinUser],

  data () {
    return {
      showPassword: false,
      icons: { mdiEye, mdiEyeOff },
      showLoginForm: true
    }
  },

  watch: {
    '$route.query.forgetPassword': {
      handler () {
        if (this.$route.query.forgetPassword) {
          this.showLoginForm = false
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$refs.authenticationServiceProvider.onMounted()
  },

  methods: {
    async onLogin () {
      if (this.isTemplateFromSite) {
        await this.$router.push({ name: 'select-idea', query: this.$router.currentRoute.query })
      } else {
        await this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  padding: 5%;
  background: #F5F6FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #292f4d;

  @include media-breakpoint-up($sm) {
    padding: 10%;
  }

  .form-wrapper {
    width: 100%;
    max-width: 550px;

    .title-wrapper {
      margin-bottom: 40px;

      h1 {
        max-width: 450px;
        margin: 20px auto 0px;
        font-size: 26px;

        @include media-breakpoint-up($sm) {
          font-size: 32px;
        }
      }
      p {
        font-size: 16px;

        @include media-breakpoint-up($sm) {
          font-size: 18px;
        }
      }
    }

    .line-through {
      overflow: hidden;
      text-align: center;

      &:before,
      &:after {
        background-color: rgba(41, 47, 77, 0.09);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }

      &:before {
        right: 1rem;
        margin-left: -50%
      }

      &:after {
        left: 1rem;
        margin-right: -50%;
      }
    }

    span {
      text-transform: none;
    }

    img {
      width: 19px;
    }

    .button-continue {
      text-transform: uppercase;
    }

    .button-google {
      background: white;

      span {
        color: #292f4d;
      }
    }

    .forgot-password {
      margin-top: 60px;
    }
  }
}
</style>
